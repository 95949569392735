<template>
  <div>
    <h4 class="mb-1">
      {{ $t(title || "Nº reserva", locale) }}
    </h4>
    <span>{{ booking.localizator }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
  },
};
</script>
