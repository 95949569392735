<template>
  <div>
    <h4 class="mb-2">
      {{ $t("emails.common.toBeChargedByTheOwner", locale) }}
    </h4>
    <b-list-group flush>
      <b-list-group-item
        v-for="bookingService in services"
        :key="bookingService.uuid"
        class="d-flex justify-content-between border-0 p-0"
      >
        <span>
          {{ getServiceName(bookingService.service, locale) }}
        </span>
        <span>
          {{ bookingServiceClientTotalPriceText(bookingService) }}
        </span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
import {
  getBookingServiceClientTotalPriceText,
  getServiceName,
} from "@/utils/methods";

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      getServiceName,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
  },
  methods: {
    bookingServiceClientTotalPriceText(bookingService) {
      return getBookingServiceClientTotalPriceText(
        bookingService,
        this.booking.nights,
        this.locale
      );
    },
  },
};
</script>
