<template>
  <div>
    <!-- IMAGE -->
    <b-img
      v-if="!hidePicture && accommodationPicture"
      fluid
      :src="accommodationPicture.url"
      class="w-100 mb-2"
    />

    <!-- ACCOMMODATION INFO -->
    <div>
      <div
        v-if="accommodationPicture"
        class="d-flex justify-content-between mb-1"
      >
        <div v-if="accommodationType" class="font-weight-bold">
          {{ accommodationType }}
        </div>
        <b-status-badge
          v-if="bookingStatus"
          :status="bookingStatus"
          :locale="locale"
        />
      </div>
      <h3
        v-if="accommodationName"
        class="mb-25"
        :class="{ 'mt-3': !accommodationPicture }"
      >
        {{ accommodationName }}
      </h3>
      <div v-if="accommodationLocation" class="text-primary">
        {{ accommodationLocation }}
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue";
import BStatusBadge from "@foravila-core/components/b-status-badge/BStatusBadge.vue";
import { getAccommodationTypeName } from "@/utils/methods";

export default {
  components: {
    BImg,
    BStatusBadge,
  },
  props: {
    bookingStatus: {
      type: String,
      default: null,
    },
    accommodation: {
      type: Object,
      default: null,
    },
    hidePicture: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    accommodationPicture() {
      return this.accommodation?.coverPicture || null;
    },
    accommodationType() {
      if (!this.accommodation) return null;
      return getAccommodationTypeName(this.accommodation.type, this.locale);
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    accommodationLocation() {
      if (!this.accommodation?.location) return null;
      const location = [];
      if (this.accommodation.location.city)
        location.push(this.accommodation.location.city);
      if (this.accommodation.location.region)
        location.push(this.accommodation.location.region);
      if (!location.length) return null;
      return location.join(", ");
    },
  },
};
</script>
