<template>
  <div>
    <h4 class="mb-1">
      {{ $t(title || "Fiança", locale) }}
    </h4>
    <div :class="{ 'mb-50': alertText }">
      {{ formatCurrency(securityDepositPrice) }}
    </div>
    <alert v-if="alertText" variant="secondary" :text="alertText" />
  </div>
</template>

<script>
import Alert from "@/views/bookings/booking/components/mail/Alert.vue";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    Alert,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    alertText: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
  },
  methods: {
    formatCurrency(price) {
      return formatCurrency(price);
    },
  },
};
</script>
