<template>
  <div class="price-details">
    <h4 class="mb-2">
      {{ $t(title || "Detalls del preu", locale) }}
    </h4>

    <!-- ACCOMMODATION PRICE DETAILS -->
    <h5 class="mb-50">
      {{ $t("Allotjament", locale) }}
    </h5>
    <b-list-group flush>
      <!-- ACCOMMODATION -->
      <b-list-group-item class="d-flex justify-content-between border-0 p-0">
        <span>
          {{ nightsText }} x {{ formatCurrency(averageRatePerNight) }}
        </span>
        <span>{{ formatCurrency(accommodationPrice) }}</span>
      </b-list-group-item>

      <!-- VAT (if exists) -->
      <b-list-group-item
        v-if="accommodationVatPrice"
        class="d-flex justify-content-between border-0 p-0"
      >
        <span>{{ $t("IVA", locale) }}</span>
        <span>{{ formatCurrency(accommodationVatPrice) }}</span>
      </b-list-group-item>

      <!-- IRPF (if exists) -->
      <b-list-group-item
        v-if="accommodationIrpfPrice"
        class="d-flex justify-content-between border-0 p-0"
      >
        <span>{{ $t("IRPF", locale) }}</span>
        <span>{{ formatCurrency(-accommodationIrpfPrice) }}</span>
      </b-list-group-item>

      <template
        v-if="
          (accommodationVatPrice || accommodationIrpfPrice) &&
          accommodationPriceWithVat
        "
      >
        <b-divider size="xs" />
        <!-- ACCOMMODATION PRICE -->
        <b-list-group-item class="d-flex justify-content-between border-0 p-0">
          <span>
            {{
              $t(
                "emails.ownerBookingConfirmation.priceDetails.totalAccommodationPrice",
                locale
              )
            }}
          </span>
          <span>{{ formatCurrency(accommodationPriceWithVat) }}</span>
        </b-list-group-item>
      </template>
    </b-list-group>

    <!-- ACCOMMODATION PRICE ALERT -->
    <b-alert class="mt-50" variant="secondary" :show="true">
      <div class="alert-body">
        {{
          $t(
            "emails.ownerBookingConfirmation.priceDetails.accommodationPaymentAlert",
            locale,
            { amount: formatCurrency(accommodationPriceWithVat) }
          )
        }}
      </div>
    </b-alert>

    <!-- EXTRAS PRICE DETAILS -->
    <template v-if="extras.length">
      <h5 class="mt-2 mb-50">
        {{ $t("Extres", locale) }}
      </h5>
      <b-list-group flush :class="{ 'mb-1': link }">
        <b-list-group-item
          v-for="bookingService in extras"
          :key="bookingService.uuid"
          class="d-flex justify-content-between border-0 p-0"
        >
          <span>{{ getExtraName(bookingService) }}</span>
          <span>{{ getExtraPrice(bookingService) }}</span>
        </b-list-group-item>
        <template v-if="extras.length > 1">
          <b-divider size="xs" />
          <!-- EXTRAS PRICE -->
          <b-list-group-item
            class="d-flex justify-content-between border-0 p-0"
          >
            <span>
              {{
                $t(
                  "emails.ownerBookingConfirmation.priceDetails.totalExtrasPrice",
                  locale
                )
              }}
            </span>
            <span>{{ formatCurrency(extrasPrice) }}</span>
          </b-list-group-item>
        </template>
      </b-list-group>

      <!-- EXTRAS PRICE ALERT -->
      <b-alert class="mt-50" variant="secondary" :show="true">
        <div class="alert-body">
          {{
            $t(
              "emails.ownerBookingConfirmation.priceDetails.extrasPaymentAlert",
              locale,
              { amount: formatCurrency(extrasPrice) }
            )
          }}
        </div>
      </b-alert>
    </template>

    <!-- TOURIST TAX PRICE DETAILS -->
    <template
      v-if="hasChargableTouristTax & (touristTaxChargableCostPrice > 0)"
    >
      <h5 class="mt-2 mb-50">
        {{ $t("Impost turístic", locale) }}
      </h5>
      <b-list-group flush :class="{ 'mb-1': link }">
        <b-list-group-item class="d-flex justify-content-between border-0 p-0">
          <span>{{ guestsText }}</span>
          <span>
            {{ formatCurrency(touristTaxChargableCostPrice) }}
          </span>
        </b-list-group-item>
      </b-list-group>

      <!-- TOURIST TAX PRICE ALERT -->
      <b-alert class="mt-50" variant="secondary" :show="true">
        <div class="alert-body">
          {{
            $t(
              "emails.ownerBookingConfirmation.priceDetails.touristTaxPaymentAlert",
              locale
            )
          }}
        </div>
      </b-alert>
    </template>

    <a v-if="link" class="text-primary" :href="link" target="blank">
      {{ $t("emails.common.priceMoreDetails", locale) }}
    </a>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BAlert } from "bootstrap-vue";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import { formatCurrency } from "@/utils/formatters";
import {
  getBookingServiceOwnerTotalPrice,
  getBookingServiceOwnerTotalPriceText,
  getBookingServiceOwnerUnitPriceText,
  getGuestsText,
  getNightsText,
  getServiceName,
} from "@/utils/methods";

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BDivider,
    BAlert,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formatCurrency,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    nights() {
      return this.booking?.nights || null;
    },
    nightsText() {
      return getNightsText(this.nights, this.locale);
    },
    guestsText() {
      const { adults = 0, children = 0, babies = 0 } = this.booking;
      return getGuestsText({ adults, children, babies }, this.locale);
    },
    extras() {
      return this.$store.getters["booking/ownerChargableServices"].filter(
        ({ service }) => {
          const { code } = service || {};
          return code !== "TOURIST_TAX";
        }
      );
    },
    extrasPrice() {
      return this.extras.reduce(
        (acc, bookingService) =>
          acc + getBookingServiceOwnerTotalPrice(bookingService, this.nights),
        0
      );
    },
    hasChargableTouristTax() {
      return this.$store.getters["booking/hasChargableTouristTax"];
    },
    touristTaxChargableCostPrice() {
      return this.$store.getters["booking/touristTaxChargableCostPrice"];
    },
    accommodationPrice() {
      return this.$store.getters["booking/ownerSubtotal"];
    },
    averageRatePerNight() {
      return this.$store.getters["booking/averageOwnerRatePerNight"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    accommodationVatPrice() {
      const ownerVatPercentage = this.contract?.ownerVatPercentage || null;

      if (!ownerVatPercentage) {
        return 0;
      }

      return this.accommodationPrice * (ownerVatPercentage / 100);
    },
    accommodationIrpfPrice() {
      const ownerIrpfPercentage = this.contract?.ownerIrpfPercentage || null;

      if (!ownerIrpfPercentage) {
        return 0;
      }

      return this.accommodationPrice * (ownerIrpfPercentage / 100);
    },
    accommodationPriceWithVat() {
      return (
        this.accommodationPrice +
        this.accommodationVatPrice -
        this.accommodationIrpfPrice
      );
    },
  },
  methods: {
    getExtraName(bookingService) {
      if (!bookingService) {
        return null;
      }

      const { service } = bookingService || {};
      const serviceName = getServiceName(service, this.locale);

      if (!serviceName) {
        return null;
      }

      const unitPriceText = getBookingServiceOwnerUnitPriceText(
        bookingService,
        this.locale
      );

      const totalPriceText = getBookingServiceOwnerTotalPriceText(
        bookingService,
        this.nights,
        this.locale
      );

      return unitPriceText && unitPriceText !== totalPriceText
        ? `${serviceName}: ${unitPriceText}`
        : serviceName;
    },
    getExtraPrice(bookingService) {
      return getBookingServiceOwnerTotalPriceText(
        bookingService,
        this.nights,
        this.locale
      );
    },
  },
};
</script>
