<template>
  <div>
    <h4 class="mb-1">
      {{ $t(title || "Hostes", locale) }}
    </h4>
    <div :class="{ 'mb-50': alertText }">
      {{ bookingGuests }}
    </div>
    <alert v-if="alertText" variant="secondary" :text="alertText" />
  </div>
</template>

<script>
import Alert from "@/views/bookings/booking/components/mail/Alert.vue";
import { getGuestsText } from "@/utils/methods";

export default {
  components: {
    Alert,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    alertText: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingGuests() {
      return (
        getGuestsText(
          {
            adults: this.booking?.adults || 0,
            children: this.booking?.children || 0,
            babies: this.booking?.babies || 0,
          },
          this.locale
        ) || this.$t("Pendent")
      );
    },
  },
};
</script>
