<template>
  <div class="d-flex justify-content-between">
    <!-- CHECKIN -->
    <div class="text-left">
      <h4 class="font-weight-bold mb-1">
        {{ $t("Checkin", locale) }}
      </h4>
      <div class="mb-25">
        {{ checkin }}
      </div>
      <small v-if="!hideTimes">{{
        $t("emails.common.timesFrom16", locale)
      }}</small>
    </div>

    <!-- CHECKOUT -->
    <div class="text-right">
      <h4 class="font-weight-bold mb-1">
        {{ $t("Checkout", locale) }}
      </h4>
      <div class="mb-25">
        {{ checkout }}
      </div>
      <small v-if="!hideTimes">{{
        $t("emails.common.timesUpTo10", locale)
      }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkin: {
      type: String,
      required: true,
    },
    checkout: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    hideTimes: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
